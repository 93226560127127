var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-info"},[_c('div',{staticClass:"home-info-cnt"},[_c('div',{staticClass:"left-block"},[_c('h2',{staticClass:"headline-3 fbold"},[_vm._v("Le constat")]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.users,"alt":"users"}})]),_vm._m(0)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.pieChart,"alt":"pie chart"}})]),_vm._m(1)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.coins,"alt":"coins"}})]),_vm._m(2)]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-item-icon"},[_c('img',{attrs:{"src":_vm.snooze,"alt":"snooze"}})]),_vm._m(3)])]),_c('div',{staticClass:"right-block"},[_c('h2',{staticClass:"headline-3 fbold"},[_vm._v("La solution")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('CtaButton',{attrs:{"label":"Découvrez les étapes du recrutement","white":""},on:{"click":_vm.onClickSteps}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement sont rarement spécialisés dans les commerciaux. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" De nombreux cabinets sont généralistes et très peu offrent une réelle expertise dans le recrutement de commerciaux B2B qualifiés. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement exigent souvent des frais fixes avant de travailler. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" La majorité des cabinets de recrutement demandent généralement d'avancer des frais fixes avant d'initier une recherche de candidats. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les cabinets de recrutement proposent souvent des prix élevés. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" La plupart des cabinets de recrutements proposent régulièrement des taux de commission chers, compris entre 20 et 30%. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item-cnt"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Les processus de recrutement sont souvent longs et dispersés. ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Une fois la collaboration contractualisée il est courant d'attendre des semaines avant de recevoir des candidatures et des mois avant de recruter. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v(" Un cabinet spécialisé dans les commerciaux B2B ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlysales est le cabinet de recrutement des commerciaux B2B qui propose un écosystème de service à destination des recruteurs comme des candidats pour leur faciliter le quotidien. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v("100% au succès")]),_c('div',{staticClass:"body-sm fsemibold"},[_vm._v(" (sans frais fixes avec des taux de commission compétitifs) ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlysales propose une approche du recrutement de commerciaux B2B sans risque à des tarifs bas, à partir de 16% du salaire annuel brut. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-item"},[_c('div',{staticClass:"headline-5 fbold"},[_vm._v("Process ultra rapide de 4 semaines")]),_c('div',{staticClass:"body-sm fsemibold"},[_vm._v(" (entre le début de la mission et l'embauche) ")]),_c('div',{staticClass:"body-md"},[_vm._v(" Onlysales délivre une méthodologie précise comprenant plusieurs grandes étapes sur 4 semaines pour recruter sans tarder. ")])])
}]

export { render, staticRenderFns }