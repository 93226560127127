<template>
  <div class="faq-item" :class="{'is-open': isOpen}" @click="isOpen = !isOpen">
    <img class="faq-item-icon" :src="isOpen ? MinusCircle : PlusCircle" alt="faq" />
    <h3 class="faq-title body-lg fsemi fbold">
      {{ title }}
    </h3>
    <div class="faq-description body-md">
      {{ description }}
    </div>
  </div>
</template>

<script>
import PlusCircle from 'front-lib-os/src/assets/plus_circle.svg'
import MinusCircle from 'front-lib-os/src/assets/minus_circle.svg'

export default {
  name: 'FaqItem',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isOpen: false,
      PlusCircle,
      MinusCircle
    }
  }
}
</script>

<style lang="scss">
.faq-item {
  padding: 32px;
  position: relative;
  cursor: pointer;
  border-radius: 16px;

  .faq-item-icon {
    position: absolute;
    top: 35px;
    right: 35px;
  }

  .faq-title, .faq-description {
    margin-top: 0;
    width: 584px;
    color: $neutral900;
  }

  .faq-description {
    display: none;
    white-space: pre-line;
  }

  &.is-open {
    background: #F9FAFB;
    margin-bottom: 16px;
    .faq-description {
      display: block;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .faq-item {
    .faq-title, .faq-description {
      width: 100%;
      max-width: 231px;
    }

    .faq-item-icon {
      top: 35px;
      right: 25px;
    }
  }
}

</style>