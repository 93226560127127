<template>
  <section class="home-perks">
    <div class="home-perks-cnt">
      <div class="title-block">
        <h2 class="headline-2 fbold">
          Les Avantages d’Onlysales
        </h2>
        <p class="body-lg">
          Ces avantages combinés font d'Onlysales la solution idéale pour les recruteurs à la recherche de talents commerciaux exceptionnels tout en optimisant l'efficacité et en réduisant les coûts liés au recrutement.
        </p>
      </div>

      <div class="perk-items">
        <PerkItem v-for="perkItem in perkItems" :key="perkItem.title" :title="perkItem.title" :description="perkItem.description" :icon="perkItem.icon"/>
      </div>
    </div>
  </section>
</template>

<script>
import PerkItem from '../PerkItem.vue'

export default {
  name: 'HomePerks',
  components: {
    PerkItem
  },
  data() {
    return {
      perkItems: [{
        title: 'Accès à une Base Hyper-Spécialisée',
        description: `Bénéficiez d'un accès privilégié à une base de données de profils commerciaux hyper qualifiées par l’équipe d'Onlysales. Cela garantit que chaque candidat correspond à vos besoins spécifiques en matière de compétences et d'expérience.`,
        icon: 'database'
      }, {
        title: `Chasseurs de Tête Experts`,
        description: `Notre équipe de chasseurs de tête expérimentés prend en charge la recherche des meilleurs candidats pour vous. Leur expertise en recrutement commercial assure une recherche proactive et ciblée pour trouver des talents de premier plan.`,
        icon: 'target',
      }, {
        title: `Gain de Temps Précieux`,
        description: `Libérez-vous de la tâche fastidieuse de trier des centaines de CV. Onlysales vous permet de vous concentrer sur des candidats pré-qualifiés, ce qui vous fait gagner un temps précieux dans le processus de recrutement.`,
        icon: 'clock_check',
      }, {
        title: `Réduction des Coûts`,
        description: `Économisez sur les coûts liés au recrutement. Grâce à notre modèle de rémunération au succès, vous ne payez que lorsque vous embauchez un candidat, éliminant ainsi les frais initiaux élevés.`,
        icon: 'bar_chart',
      }, {
        title: `Qualité Garantie`,
        description: `Notre engagement envers la qualité se traduit par une garantie de satisfaction. Si le candidat recruté ne répond pas à vos attentes dans un certain délai, nous nous engageons à vous fournir des solutions alternatives.`,
        icon: 'certificate',
      }, {
        title: `Flexibilité de l'Offre`,
        description: `Choisissez parmi plusieurs packages et options de tarification pour personnaliser l'offre selon les besoins de votre entreprise, qu'il s'agisse de recruter pour un poste unique ou pour plusieurs postes.`,
        icon: 'move',
      }, {
        title: `Collaboration Transparente`,
        description: `Collaborez étroitement avec notre équipe de chasseurs de tête tout au long du processus de recrutement. Vous restez constamment informé et impliqué dans la recherche des candidats.`,
        icon: 'users',
      }, {
        title: `Diversification des Talents`,
        description: `Élargissez votre bassin de talents en accédant à des candidats provenant de divers horizons et de différentes industries commerciales.`,
        icon: 'diversification',
      }, {
        title: `Optimisation de la Culture d'Entreprise`,
        description: `Les candidats qualifiés identifiés par Onlysales sont soigneusement sélectionnés pour s'adapter à la culture et à la vision de votre entreprise, favorisant ainsi une intégration en douceur et une productivité rapide.`,
        icon: 'intersect_square',
      }]
    }
  }
}
</script>

<style lang="scss">
.home-perks {
  padding: 96px 112px;
  background: $white;

  .home-perks-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .title-block {
      width: 768px;
      margin: 0 auto 64px;
      text-align: center;

      h2 {
        color: $neutral900;
      }
    }
  }

  .perk-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $desktopContainer;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-perks {
    padding: 64px 24px;

    .home-perks-cnt {
      width: 100%;

      .title-block {
        width: 100%;
        margin-bottom: 48px;
        text-align: left;
      }
    }

    .perk-items {
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      .perk-item {
        width: 100%;
        text-align: left;
        margin-bottom: 40px;

        .perk-icon {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>