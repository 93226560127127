<template>
  <section class="home-pricing">
    <div class="home-pricing-header">
      <div class="home-pricing-header-cnt">
        <h2 class="headline-2 fbold">Tarification</h2>
        <div class="body-lg">
          Une tarification simple et transparente qui évolue avec vous.<br class="desktop"/> Essayez gratuitement n'importe quel plan pendant 30 jours.
        </div>
      </div>
    </div>

    <div class="home-pricing-items">
      <PriceItem v-for="item in priceItems" :key="item.price" :price="item.price" :feature="item.feature" />
    </div>
  </section>
</template>

<script>
import PriceItem from '../PriceItem.vue'

export default {
  name: 'HomePricing',
  components: {
    PriceItem
  },
  data() {
    return {
      priceItems: [{
        price: '14%',
        feature: 'Garantie 1 mois à 100%'
      }, {
        price: '16%',
        feature: 'Garantie 4 mois à 50%'
      }, {
        price: '18%',
        feature: 'Garantie 8 mois à 100%'
      }]
    }
  }
}
</script>

<style lang="scss">
.home-pricing {
  background: $white;
  padding-bottom: 96px;

  .home-pricing-header {
    background: $neutral900;
    padding: 96px 112px 192px;
    
    .home-pricing-header-cnt {
      width: $desktopContainer;
      margin: 0 auto;
      text-align: center;

      .headline-2 {
        color: white;
      }

      .body-lg {
        width: 768px;
        margin: 0 auto;
        color: $neutral200;
      }
    }
  }

  .home-pricing-items {
    width: $desktopContainer;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: -96px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-pricing {
    width: 100%;

    .home-pricing-header {
      padding: 64px 24px 160px;
      width: 100%;

      .home-pricing-header-cnt {
        width: 100%;

        .headline-2 {
          margin-top: 0;
        }

        .body-lg {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .home-pricing-items {
      width: 100%;
      flex-direction: column;
      margin-top: -64px;
    }
  }
}

</style>
