<template>
  <section class="home-hero">
    <h1 class="headline-1 fbold">Recrutez un commercial qualifié</h1>
    <div class="body-lg">
      Trouvez des talents grâce à notre équipe de chasseurs de tête 100% spécialisés dans les commerciaux.
    </div>
    <div class="cta">
      <CtaButton
        label="Recruter un commercial"
        :black="true"
        @click="onClickHelpMeRecruit"
      />
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";

export default {
  name: "HomeHero",
  components: {
    CtaButton,
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open(
        "https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud"
      );
    },
    onClickOsJobs() {
      window.open("https://jobs.onlysales.fr/poster-un-emploi");
    },
  },
};
</script>

<style lang="scss">
.home-hero {
  color: $neutral900;
  background-color: $primary300;
  padding: 96px 112px;
  text-align: center;

  .headline-1 {
    margin: 12px auto 24px;
    max-width: 960px;
  }

  .body-lg {
    margin: 0 auto 40px;
    max-width: 768px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-hero {
    padding: 64px 24px;
    text-align: left;

    .headline-1 {
      font-size: 40px;
      line-height: 48px; /* 120% */
    }
  }
}
</style>