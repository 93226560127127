<template>
  <section class="home-info">
    <div class="home-info-cnt">
      <div class="left-block">
        <h2 class="headline-3 fbold">Le constat</h2>

        <div class="info-item">
          <div class="info-item-icon">
            <img :src="users" alt="users" />
          </div>
          <div class="info-item-cnt">
            <div class="headline-5 fbold">
              Les cabinets de recrutement sont rarement spécialisés dans les
              commerciaux.
            </div>
            <div class="body-md">
              De nombreux cabinets sont généralistes et très peu offrent une
              réelle expertise dans le recrutement de commerciaux B2B qualifiés.
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-icon">
            <img :src="pieChart" alt="pie chart" />
          </div>
          <div class="info-item-cnt">
            <div class="headline-5 fbold">
              Les cabinets de recrutement exigent souvent des frais fixes avant
              de travailler.
            </div>
            <div class="body-md">
              La majorité des cabinets de recrutement demandent généralement
              d'avancer des frais fixes avant d'initier une recherche de
              candidats.
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-icon">
            <img :src="coins" alt="coins" />
          </div>
          <div class="info-item-cnt">
            <div class="headline-5 fbold">
              Les cabinets de recrutement proposent souvent des prix élevés.
            </div>
            <div class="body-md">
              La plupart des cabinets de recrutements proposent régulièrement
              des taux de commission chers, compris entre 20 et 30%.
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-icon">
            <img :src="snooze" alt="snooze" />
          </div>
          <div class="info-item-cnt">
            <div class="headline-5 fbold">
              Les processus de recrutement sont souvent longs et dispersés.
            </div>
            <div class="body-md">
              Une fois la collaboration contractualisée il est courant
              d'attendre des semaines avant de recevoir des candidatures et des
              mois avant de recruter.
            </div>
          </div>
        </div>
      </div>

      <div class="right-block">
        <h2 class="headline-3 fbold">La solution</h2>

        <div class="solution-item">
          <div class="headline-5 fbold">
            Un cabinet spécialisé dans les commerciaux B2B
          </div>
          <div class="body-md">
            Onlysales est le cabinet de recrutement des commerciaux B2B qui
            propose un écosystème de service à destination des recruteurs comme
            des candidats pour leur faciliter le quotidien.
          </div>
        </div>

        <div class="solution-item">
          <div class="headline-5 fbold">100% au succès</div>
          <div class="body-sm fsemibold">
            (sans frais fixes avec des taux de commission compétitifs)
          </div>
          <div class="body-md">
            Onlysales propose une approche du recrutement de commerciaux
            B2B sans risque à des tarifs bas, à partir de 16% du salaire annuel
            brut.
          </div>
        </div>

        <div class="solution-item">
          <div class="headline-5 fbold">Process ultra rapide de 4 semaines</div>
          <div class="body-sm fsemibold">
            (entre le début de la mission et l'embauche)
          </div>
          <div class="body-md">
            Onlysales délivre une méthodologie précise comprenant
            plusieurs grandes étapes sur 4 semaines pour recruter sans tarder.
          </div>
        </div>

        <CtaButton
          label="Découvrez les étapes du recrutement"
          white
          @click="onClickSteps"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";

import users from "front-lib-os/src/assets/users.svg";
import pieChart from "front-lib-os/src/assets/pie_chart.svg";
import snooze from "front-lib-os/src/assets/snooze.svg";
import coins from "front-lib-os/src/assets/coins.svg";

export default {
  name: "HomeInfo",
  components: {
    CtaButton,
  },
  data() {
    return {
      users,
      pieChart,
      snooze,
      coins,
    };
  },
  methods: {
    onClickSteps() {
      document.getElementById("steps").scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
.home-info {
  padding: 96px 112px 32px;
  background: $white;

  h2 {
    margin: 0 0 32px;
  }

  .home-info-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .left-block {
      padding-top: 40px;
      float: left;
      width: 576px;

      .info-item {
        margin-bottom: 32px;

        .info-item-icon {
          float: left;
          position: relative;
          top: 12px;
          margin: 0 auto;
          display: flex;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          padding: 11.029px;
          justify-content: center;
          align-items: center;

          border-radius: 12px;
          background: $primary50;

          img {
            width: 20px;
            height: 20px;
            transform: rotate(-45deg);
          }
        }

        .info-item-cnt {
          float: right;
          width: 504px;

          .headline-5 {
            margin-bottom: 8px;
          }
        }

        &::after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }

    .right-block {
      float: right;
      width: 576px;
      padding: 40px;
      background: $primary25;
      border-radius: 16px;

      .solution-item {
        margin-bottom: 32px;

        .headline-5 {
          margin-bottom: 4px;
        }

        .body-sm {
          position: relative;
          top: -10px;
          color: $neutral900;
        }
      }

      .cstm-button {
        margin-top: 32px;
        button {
          background: $primary25;
        }
      }
    }
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-info {
    padding: 64px 24px 0;

    .home-info-cnt {
      width: $mobileContainer;

      .left-block,
      .right-block {
        width: 100%;
        float: none;

        &.left-block {
          margin-bottom: 62px;
        }

        &.right-block {
          padding: 40px 20px;

          .headline-5 {
            line-height: 24px;
            margin-bottom: 12px;
          }
        }

        .info-item {
          .info-item-icon {
            float: none;
            margin: 0 0 32px;
          }

          .info-item-cnt {
            float: none;
            width: 100%;
          }
        }

        .cstm-button button {
          padding-left: 0;
          padding-right: 0;
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}
</style>