<template>
  <div class="perk-item">
    <div class="perk-icon">
      <img :src="icons[icon]" :alt="title" />
    </div>
    <div class="headline-5 fbold">
      {{ title }}
    </div>
    <div class="body-md">
      {{ description }}
    </div>
  </div>
</template>

<script>
import bar_chart from 'front-lib-os/src/assets/bar_chart.svg'
import clock_check from 'front-lib-os/src/assets/clock_check.svg'
import database from 'front-lib-os/src/assets/database.svg'
import target from 'front-lib-os/src/assets/target.svg'
import certificate from 'front-lib-os/src/assets/certificate.svg'
import move from 'front-lib-os/src/assets/move.svg'
import users from 'front-lib-os/src/assets/users.svg'
import diversification from 'front-lib-os/src/assets/diversification.svg'
import intersect_square from 'front-lib-os/src/assets/intersect_square.svg'

export default {
  name: 'PerkItem',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      icons: {
        bar_chart,
        clock_check,
        database,
        target,
        certificate,
        move,
        users,
        diversification,
        intersect_square
      }
    }
  }
}
</script>

<style lang="scss">
.perk-item {
  width: 384px;
  text-align: center;
  margin-bottom: 78px;

  .perk-icon {
    margin: 0 auto;
    display: flex;
    width: 56px;
    height: 56px;
    transform: rotate(45deg);
    padding: 11.029px;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    background: $primary50;

    img {
      width: 24px;
      height: 24px;
      transform: rotate(-45deg);
    }
  }

  .headline-5 {
    margin: 32px 0 8px;
  }
}
</style>
