<template>
  <section class="home-faq">
    <div class="home-faq-cnt">
      <div class="faq-left">
        <h2 class="headline-2 fbold">FAQs</h2>
        <div class="body-md">
          Tout ce que vous devez savoir sur le produit et la facturation. Vous
          ne trouvez pas la réponse que vous cherchez ? N'hésitez pas à
          contacter notre équipe.
        </div>
        <CtaButton label="Contactez nous" white @click="onClickContactUs" />
      </div>

      <div class="faq-right">
        <FaqItem
          v-for="(item, index) in faq"
          :key="index.title"
          :title="item.title"
          :description="item.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";
import FaqItem from "../FaqItem.vue";

export default {
  name: "HomeFaq",
  components: {
    CtaButton,
    FaqItem,
  },
  data() {
    return {
      faq: [
        {
          title: `Quels types de profils commerciaux pouvez-vous nous aider à recruter ?`,
          description: `Onlysales est spécialisé dans le recrutement de tous les types de profils commerciaux, qu'il s'agisse de représentants des ventes, de directeurs commerciaux, de responsables du développement des affaires, de chefs de vente en ligne, et bien plus encore. Notre base de données comprend une variété de compétences et d'expériences pour répondre à vos besoins spécifiques.`,
        },
        {
          title: `Qu'est-ce qui distingue Onlysales des autres solutions de recrutement ?`,
          description: `Onlysales a pour ambition de créer la première communauté de commerciaux en France d'ici à 3 ans et propose déjà un écosystème de service à destination des recruteurs comme des candidats pour leur faciliter le quotidien et les mettre en relation. 

Grâce à cette hyper spécialisation, à un modèle 100 % au succès à tarif compétitif et à un processus de recrutement rapide et efficace, Onlysales vous permet de recruter rapidement les meilleurs profils sans risque financier.`,
        },
        {
          title: `Comment fonctionne le modèle de rémunération au succès ?`,
          description: `Le modèle de rémunération au succès d'Onlysales est très simple et sans risques.

Après avoir signé un mandat simple de recrutement nous étudions en détail les besoins de votre recherche pour créer une fiche de poste.

À partir de cette fiche de poste et des différents échanges que nous avons eu avec vous nous lançons notre processus de sourcing qui en deux à trois semaines permet d'identifier et de pré-qualifier les meilleurs candidats du marché.

Nous vous les présentons ensuite pour qu'ils soient intégrés dans votre processus d'embauche si leur profil est en adéquation avec votre recherche.

Et c'est uniquement en cas d'embauche que nous facturons une prestation correspondant à votre taux de commission.`,
        },
        {
          title: `Combien de temps faut-il pour trouver des candidats qualifiés avec Onlysales ?`,
          description: `Il faut compter entre une à trois semaines pour obtenir des candidatures pré-qualifiées tant au niveau des hard skills que des soft skills

Contrairement à d'autres cabinets, nous avons à cœur de faire aboutir rapidement, dans un délai de 4 semaines maximum dans la plupart des cas, nos recherches pour pouvoir votre poste.`,
        },
        {
          title: `Pouvons-nous collaborer étroitement avec votre équipe de chasseurs de tête ?`,
          description: `Confier un recrutement à Onlysales c'est comme intégrer une ressource externe spécialisée dans la chasse de tête qui va dialoguer régulièrement avec vous par téléphone ou par email pour affiner le sourcing et trouver progressivement les meilleurs candidats.

En plus nous avons comme principe de répondre dans un délai de 48 heures maximum à tous nos clients en amenant des réponses les plus précises possibles.`,
        },
        {
          title: `Y a-t-il une garantie de satisfaction avec Onlysales ?`,
          description: `En plus d'offrir un modèle au succès sans risque et à taux compétitif, Onlysales propose également une garantie de remplacement en fonction du taux de commission que vous avez choisi.

Cette garantie implique que nos équipes prendront à nouveau à leur charge le sourcing de candidats pré-qualifiés dans le cas où l'un de ceux proposés et embauchés venait à quitter l'entreprise, que ce soit de votre fait ou du sien.`,
        },
        {
          title: `Quels sont les coûts associés à Onlysales ?`,
          description: `Les coûts de recrutement associés à notre cabinet sont liés au taux de commission que vous avez choisi.

Celui-ci est appliqué sur le package global du candidat qui se verrait être embauché par votre entreprise.

En dehors de ce taux, aucun autre coût visible ou dissimulé n'est à prévoir. 

Vous savez exactement où vous allez d'un point de vue financier !`,
        },
      ],
    };
  },
  methods: {
    onClickContactUs() {
      document.getElementById("contact").scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
.home-faq {
  background: $white;
  padding: 96px 112px;
  text-align: left;

  .home-faq-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .faq-left {
      width: 488px;
      float: left;

      .headline-2 {
        color: $neutral900;
        margin: 0;
      }

      .body-md {
        color: $neutral600;
        margin: 16px 0 40px;
      }
    }

    .faq-right {
      width: 696px;
      float: right;
    }
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-faq {
    padding: 0 24px;

    .home-faq-cnt {
      width: 100%;

      .faq-left {
        width: 100%;
        float: none;

        .cstm-button {
          margin-bottom: 32px;
        }
      }

      .faq-right {
        width: 100%;
        float: none;
      }
    }
  }
}
</style>