<template>
  <div class="price-item">
    <div class="price-item-header">
      <!-- <div class="illimited-badge uppercase-xs fbold">Illimité 1 mois</div> -->
      <div class="headline-2 fbold">{{price}}</div>
      <div class="body-md">{{feature}}</div>
    </div>

    <div class="price-item-cnt">
      <ul>
        <li class="body-md">
          <span>100% spécialisé commerciaux B2B</span>
        </li>
        <li class="body-md">
          <span>100% au succès sans frais fixes</span>
        </li>
        <li class="body-md">
          <span>Tarif compétifif dès le 1er recrutement </span>
        </li>
        <li class="body-md">
          <span>Processus rapide en 4 semaines</span>
        </li>
        <li class="body-md">
          <span>Embauche dans 75% des cas</span>
        </li>
        <li class="body-md">
          <span>Optimisation du tarif grâce au Parrainage</span>
        </li>
      </ul>
    </div>

    <div class="price-item-actions">
      <CtaButton label="Recruter un commercial" block @click="onClickHelpMeRecruit" />
      <CtaButton label="Contactez-nous" block white @click="onClickContactUs"/>
    </div>
  </div>
</template>
  
<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";
export default {
  name: "PriceItem",
  props: {
    price: {
      type: String,
      required: true,
    },
    feature: {
      type: String,
      required: true,
    },
  },
  components: {
    CtaButton,
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open("https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud");
    },
    onClickContactUs() {
      document.getElementById("contact").scrollIntoView()
    }
  }
};
</script>
  
<style lang="scss">
.price-item {
  width: 384px;
  border-radius: 16px;
  border: 1px solid $neutral200;
  background: #fffffd;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  text-align: center;

  .price-item-header {
    margin: 0 32px;
    padding: 32px 0;
    border-bottom: 1px solid $neutral200;

    .illimited-badge {
      padding: 8px 16px;
      border-radius: 16px;
      border: 1px solid $primary300;
      background: $primary25;
      display: inline-block;
      color: $neutral700;
    }

    .headline-2 {
      margin: 24px 0 8px;
      color: $neutral900;
    }

    .body-md {
      color: $neutral600;
    }

    // background:url('@/assets/images/icons.svg#edit') no-repeat;
    // list-style-image: url(img/iphone.svg)
  }

  .price-item-cnt {
    padding: 32px 0;
    margin: 0 32px;
    border-bottom: 1px solid $neutral200;

    ul {
      padding-left: 32px;
      text-align: left;
      margin: 0;
      list-style-image: url("front-lib-os/src/assets/check.svg");

      li {
        margin-bottom: 12px;
        span {
          position: relative;
          top: -4px;
          right: -12px;
        }
      }
    }
  }

  .price-item-actions {
    padding: 32px 0;
    margin: 0 32px;

    .cstm-button {
      margin: 0 8px;

      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .price-item {
    width: calc(100% - 24px*2);
    margin: 0 auto 32px;

    .price-item-header {
      margin: 0 24px;
    }

    .price-item-cnt {
      margin: 0 24px;
    }

    .price-item-actions {
      margin: 0 24px;
    }
  }
}
</style>